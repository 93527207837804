<template>
  <DxDataGrid
      ref="rcgrid"
      :data-source="dataSource"
      :show-borders="true"
      :remote-operations="gridRemoteOperations"
  >
    <DxColumn
        dataField="field_name"
        :caption="$t('reporting.tabs.columns.field_name')"
        :width="'20%'"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('reporting.validation.field_name_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="title"
        :caption="$t('reporting.tabs.columns.column_title')"
        :width="'20%'"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('reporting.validation.title_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="width"
        datatype="number"
        alignment="center"
        :caption="$t('reporting.tabs.columns.width')"
        :width="'10%'"
        :allowFiltering="false"
        :allowSorting="false"
    >
      <DxRequiredRule :message="$t('reporting.validation.width_is_required')"/>
    </DxColumn>
    <!-- lookup -->
    <DxColumn
        dataField="visible"
        dataType="number"
        alignment="center"
        :caption="$t('reporting.tabs.columns.visible')"
        :width="'20%'"
        :filter-operations="['=']"
    >
      <DxLookup
          :data-source="visibleList"
          value-expr="value"
          display-expr="text"
      />
    </DxColumn>
    <DxColumn
        dataField="created_at"
        :caption="$t('common.created_at')"
        dataType="date"
        format="yyyy-MM-dd"
        alignment="center"
        :filter-operations="['=']"
        :width="'10%'"
        :formItem="{
            visible: false
        }"
    />

    <DxColumn
        dataField="updated_at"
        :caption="$t('common.updated_at')"
        dataType="date"
        format="yyyy-MM-dd"
        alignment="center"
        :width="'10%'"
        :filter-operations="['=']"
        :formItem="{
            visible: false
        }"
    />
    <DxFilterRow :visible="true"/>
    <DxEditing
        :allow-adding="allowEditing()"
        :allow-updating="allowEditing()"
        :allow-deleting="allowDeleting"
        mode="form"
        :use-icons="true"
    >
      <DxTexts
          :confirm-delete-message="$t('reporting.validation.confirm_delete')"
          :confirm-delete-title="$t('confirm_delete_title')"
      />
    </DxEditing>
  </DxDataGrid>
</template>

<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxLookup,
  DxRequiredRule,
  DxTexts
} from 'devextreme-vue/data-grid';
import {mapActions} from "vuex";
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import {canDelete, canEdit} from "@/helpers/Permissions";

export default {
  props: {
    data: Object,
  },
  components: {
    DxLookup,
    DxFilterRow,
    DxRequiredRule,
    DxTexts,
    DxEditing,
    DxColumn,
    DxDataGrid,
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering : true,
        sorting: true
      },
    }
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: 'id',
          load: this.load,
          insert: this.insert,
          update: this.update,
          remove: this.remove
        })
      });
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    visibleList() {
      return [
        {value: 1, text: this.$t('common.yes')},
        {value: 0, text: this.$t('common.no')}
      ];
    },
  },
  methods: {
    ...mapActions({
      get: 'reporting/getReportColumns',
      create: 'reporting/createReportColumn',
      change: 'reporting/updateReportColumn',
      delete: 'reporting/deleteReportColumn',
    }),
    async load(data) {
      let dataSource =  {
        data:[],
        totalCount:0
      };

      await this.get({
        report_id: this.data.id,
        filter: data.filter,
        sort: data.sort,
      }).then((resp) => {
        dataSource.data = resp.data.data;
        dataSource.totalCount = resp.data.pagination.total;
      });

      return dataSource;
    },
    async insert(data) {
      await this.create(
          Object.assign({}, data, {
            report_id: this.data.id,
          })
      )
    },
    async update(key, obj) {
      const updatable = Object.assign(
          {},
          this.dataSource.items().find(item => item.id === key),
          obj
      );

      await this.change(
          Object.assign({}, updatable, {
            id: key,
            report_id: this.data.id,
          })
      )
    },
    remove(id) {
      this.delete({
        id: id,
      });
    },
    allowEditing(e) {
      return this.canEdit;
    },
    allowDeleting(e) {
      return this.canDelete && e.row.data.is_deletable === 1;
    },
  },
}
</script>
