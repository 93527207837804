<template>
  <div class="row">
    <Toggle :toggleText="$t('tools.configurator.import_settings.email_import_is')" :isActive="is_active"
      :onChange="handleToggleEmail"></Toggle>
    <div v-if="is_active">
      <div class="row">
        <div class="form-group col-lg-4" style="margin-bottom: 0px;">
          <label class="control-label">{{ $t('tools.configurator.import_settings.credentials') }}</label>
          <b-form-select v-model="data.selected_email_credential" class="form-control"
            @change="onEmailCredentialsChanged">
            <option v-for="option in credential_options" :key="option.id" :value="option.value">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label class="control-label">{{ $t("email") }}</label>
          <input ref="emailInput" v-model="data.credential_user_name" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_user_name.$error }" autocomplete="off" />
        </div>
        <div class="col-lg-4">
          <label class="control-label">{{ $t("password") }}</label>
          <input type="password" v-model="data.credential_password" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_password.$error }" autocomplete="off" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label class="control-label">{{ $t("tools.configurator.import_settings.host") }}</label>
          <input v-model="data.credential_host" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_host.$error }" autocomplete="off" />
        </div>
        <div class="col-lg-4">
          <label class="control-label">{{ $t("tools.configurator.import_settings.port") }}</label>
          <input type="number" min="0" v-model="data.credential_port" class="form-control"
            :class="{ 'field-error': this.$v.data.credential_port.$error }" autocomplete="off" />
        </div>
        <div class="col-lg-2">
          <label class="control-label">{{ $t("tools.configurator.import_settings.encryption") }}</label>
          <b-form-select v-model="data.credential_encryption" class="form-control" @change="onEncryptionOptionChanged">
            <option v-for="option in encryption_options" :key="option.id" :value="option.value">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="col-lg-4" style="margin-bottom: 0px; margin-top: 10px;">
        <label class="control-label">{{ $t("tools.configurator.import_settings.credential_configuration")
          }}</label>
        <b-form-select v-model="data.selected_credential_configuration" class="form-control"
          @change="onCredentialConfigChanged">
          <option v-for="option in configuration_options" :key="option.id" :value="option.value">
            {{ option.text }}
          </option>
        </b-form-select>
      </div>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-lg-6">
          <label class="control-label">{{ $t("tools.configurator.import_settings.subject_text") }}</label>
          <div class="row">
            <div class="col-lg-8" style="padding-right: 0px;">
              <input v-model="data.configuration_subject" class="form-control"
                :class="{ 'field-error': this.$v.data.configuration_subject.$error }" autocomplete="off" />
            </div>
            <div class="col-lg-4" style="padding-left: 0px;">
              <b-form-select v-model="data.configuration_subject_match" class="form-control"
                @change="onSubjectMatchOptionChanged">
                <option v-for="option in text_match_options" :key="option.id" :value="option.value">
                  {{ option.text }}
                </option>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <label class="control-label">{{ $t("tools.configurator.import_settings.attachment_file_name") }}</label>
          <div class="row">
            <div class="col-lg-8" style="padding-right: 0px;">
              <input v-model="data.configuration_attachment_file_name" class="form-control"
                :class="{ 'field-error': this.$v.data.configuration_attachment_file_name.$error }" autocomplete="off" />
            </div>
            <div class="col-lg-4" style="padding-left: 0px;">
              <b-form-select v-model="data.configuration_attachment_file_name_match" class="form-control"
                @change="onAttachmentFileMatchOptionChanged">
                <option v-for="option in text_match_options" :key="option.id" :value="option.value">
                  {{ option.text }}
                </option>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label class="control-label">{{ $t("tools.configurator.import_settings.email_from") }}</label>
          <input ref="emailFromInput" v-model="data.configuration_email_from" class="form-control"
            :class="{ 'field-error': this.$v.data.configuration_email_from.$error }" autocomplete="off" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle.vue";
import { required, numeric, email } from 'vuelidate/lib/validators'
import { errorMessage } from "~/helpers/common";

export default {
  components: {
    Toggle
  },
  name: 'ImportEmailSettings',
  data: function () {
    return {
      is_active: this.emailConfig ? this.emailConfig.is_active === 1 : false,
      credential_options: [],
      configuration_options: [],
      text_match_options: [
        { id: 1, value: 'exact_match', text: this.$t("exact_match") },
        { id: 2, value: 'contain', text: this.$t("contains") },
      ],
      encryption_options: [
        { id: 1, value: 'ssl', text: 'ssl' },
        { id: 2, value: 'tls', text: 'tls' },
      ],
      data: {
        selected_email_credential: '',
        selected_credential_configuration: '',
        credential_user_name: '',
        credential_password: '',
        credential_host: '',
        credential_port: 0,
        credential_encryption: '',
        configuration_subject: '',
        configuration_attachment_file_name: '',
        configuration_subject_match: 'contain',
        configuration_attachment_file_name_match: 'contain',
        configuration_email_from: '',
      },
    };
  },
  props: ['emailConfig', 'emailCredentials'],

  mounted() {
  },
  methods: {
    /**
      * Updates the selected email credential and sets the corresponding username and password in the data object.
      * Then, it loads the configuration options and triggers the onCredentialConfigChanged method.
      *
      * @param {string} val - The UUID of the selected email credential.
      */
    onEmailCredentialsChanged(val) {
      this.data.selected_email_credential = val;
      if (this.data.selected_email_credential) {
        let selectedCred = this.emailCredentials.find(credential => credential.uuid === this.data.selected_email_credential);
        if (selectedCred) {
          this.data.credential_user_name = selectedCred.config.username;
          this.data.credential_password = selectedCred.config.password;
          this.data.credential_encryption = selectedCred.config.encryption;
          this.data.credential_host = selectedCred.config.host;
          this.data.credential_port = selectedCred.config.port;
        }
      } else {
        this.data.credential_user_name = '';
        this.data.credential_password = '';
        this.data.credential_encryption = this.encryption_options[0].value;
        this.data.credential_host = '';
        this.data.credential_port = 0;
      }

      this.loadConfigurationOptions();
      this.onCredentialConfigChanged('');
    },
    /**
    * Updates the email configuration based on the selected credential.
    * @param {any} val - The new value of the selected email configuration.
    */
    onCredentialConfigChanged(val) {
      this.data.selected_credential_configuration = val;
      let selectedCred = this.emailCredentials.find(credential => credential.uuid === this.data.selected_email_credential);
      if (selectedCred && this.data.selected_credential_configuration) {
        let selectedConfig = selectedCred.source_configurations.find(sourceConfig => sourceConfig.uuid === this.data.selected_credential_configuration);
        if (selectedConfig) {
          this.data.configuration_attachment_file_name = selectedConfig.config.attachment_file_name;
          this.data.configuration_subject = selectedConfig.config.subject;
          this.data.configuration_subject_match = selectedConfig.config.subject_match ? selectedConfig.config.subject_match : this.text_match_options[0].value;
          this.data.configuration_attachment_file_name_match = selectedConfig.config.attachment_file_name_match ? selectedConfig.config.attachment_file_name_match : this.text_match_options[0].value;
          this.data.configuration_email_from = selectedConfig.config.email_from;
        }
      } else {
        this.data.configuration_attachment_file_name = '';
        this.data.configuration_subject = '';
        this.data.configuration_subject_match = this.text_match_options[0].value;
        this.data.configuration_attachment_file_name_match = this.text_match_options[0].value;
        this.data.configuration_email_from = '';
      }
    },
    loadConfigurationOptions() {
      if (this.data.selected_email_credential) {
        let optionId = 1;
        let selectedCred = this.emailCredentials.find(credential => credential.uuid === this.data.selected_email_credential);
        if (selectedCred) {
          this.configuration_options = [
            { id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_configuration") + ' - ' },
            ...selectedCred.source_configurations.map(sourceConfig => {
              return {
                id: optionId++,
                value: sourceConfig.uuid,
                text: sourceConfig.config.subject + ' - ' + sourceConfig.config.attachment_file_name,
              };
            })
          ];
        }
      } else {
        this.configuration_options = [{ id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_configuration") + ' - ' }];
      }
    },
    handleToggleEmail(is_checked) {
      this.is_active = is_checked;
      this.emailConfig.is_active = is_checked ? 1 : 0;
    },
    emailConfigurationChange() {
      this.is_active = this.emailConfig ? this.emailConfig.is_active === 1 : false;
      this.data.selected_email_credential = '';
      this.data.selected_credential_configuration = '';
      let temp_email_configuration = '';

      if (this.is_active) {
        if (this.emailConfig.source_config_uuid && this.emailConfig.source_config_uuid !== '') {
          let credential = this.emailCredentials.find(credential => credential.source_configurations.find(sourceConfig => sourceConfig.uuid === this.emailConfig.source_config_uuid));
          if (credential) {
            this.data.selected_email_credential = credential.uuid;
            this.data.selected_credential_configuration = this.emailConfig.source_config_uuid;
            temp_email_configuration = this.emailConfig.source_config_uuid;
          }
        }
      }

      this.onEmailCredentialsChanged(this.data.selected_email_credential);
      this.onCredentialConfigChanged(temp_email_configuration);
    },
    validate() {
      /**
       * Validates the form fields for importing email settings.
       * If the 'is_active' flag is true, the following fields are required:
       * - data.credential_user_name
       * - data.credential_password
       * - data.credential_encryption
       * - this.data.credential_host
       * - this.data.credential_port
       * - data.configuration_subject
       * - data.configuration_attachment_file_name
       * - data.configuration_subject_match
       * - data.configuration_attachment_file_name_match
       * - data.configuration_email_from
       * 
       * If the 'is_active' flag is false, the function returns true.
       * 
       * @param {boolean} is_active - Flag indicating whether the email settings are active or not.
       * @returns {object|boolean} - An object containing validation rules for the required fields if 'is_active' is true, otherwise returns true.
       */

      let isValid = true;

      if (this.is_active) {
        this.$v.data.$touch();
        if (this.$v.data.$invalid) {
          errorMessage(this, this.$t('fill_out_error'));
          isValid = false;
        }
      }

      return isValid;
    },
    onSubjectMatchOptionChanged(val) {
    },
    onAttachmentFileMatchOptionChanged(val) {
    },
    onEncryptionOptionChanged(val) {
    },
  },
  watch: {
    emailConfig: {
      handler: function (newVal) {
        this.emailConfigurationChange();
      },
      deep: true,
    },
    emailCredentials: {
      handler: function (newVal) {
        this.emailCredentials = newVal;

        let optionId = 1;
        this.credential_options = [
          { id: '', value: '', text: ' - ' + this.$t("tools.configurator.import_settings.new_credential") + ' - ' },
          ...this.emailCredentials.map(credential => {
            return {
              id: optionId++,
              value: credential.uuid,
              text: credential.config.username,
            };
          })
        ];
      },
      deep: true,
    },
    data: {
      handler: function (newVal) {
        this.$emit('onEmailDataChanged', newVal);
      },
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        credential_user_name: {
          required, email
        },
        credential_password: {
          required,
        },
        credential_host: {
          required,
        },
        credential_port: {
          required, numeric
        },
        credential_encryption: {
          required,
        },
        configuration_subject: {
          required,
        },
        configuration_subject_match: {
          required,
        },
        configuration_attachment_file_name: {
          required,
        },
        configuration_attachment_file_name_match: {
          required,
        },
        configuration_email_from: {
          required, email
        },
      },
    }
  },
}
</script>
