const state = () => {
  return {
    import_templates: [],
    import_template: {},
    required_attributes: [],
    import_configurations: [],
    import_source_credentials: [],
    import_validated_data: [],
  }
}

export default state;
