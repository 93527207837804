import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getParcels({commit}, payload) {
        return api.fetch(endpoints.PARCELS, payload, commit,(response) => {
          commit(types.SET_PARCELS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getParcel({commit}, payload) {
      return api.fetch(endpoints.PARCELS+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      });
    },
    createParcel({commit}, payload) {
      return api.post(endpoints.PARCELS, payload,  commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      });
    },
    updateParcel({commit}, payload) {
      return api.put(endpoints.PARCELS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_PARCEL, response.data);
      })
    },
    deleteParcel({commit}, payload) {
      return api.remove(endpoints.PARCELS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_PARCEL, response.data);
      })
    },
}

export default actions;
