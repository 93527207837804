<template>
  <div>
    <DxPopup
      id="dx-dialog"
      height="100vh"
      width="100vw"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
    >
      <template #title>
        <div class="work-detail-header">
          <div class="left_side" @click="buttonCloseEvent">
            <img src="@/assets/images/icons/arrow-back.svg" />
            <span>Înapoi</span>
          </div>
          <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div class="work-detail-container">
          <div class="title-container">
            <img class="title-image" src="@/assets/images/icons/droplet-simple-green.svg" alt="Articole" />
            <div class="title-text">{{work_name}}</div>
            <div class="subtitle-text">{{work_date}}</div>
          </div>
          <div class="title_separator"></div>
          <DxScrollView class="work-detail-scrollable-container" :use-native="false" :bounce-enabled="true" :element-attr="scrollAttributes">
            <div>
              <div class="work-container" v-for="(tractor, t_index) in workDetailsResult" :key="t_index">
                  <div class="machine-container">
                    <img class="machine-image" src="@/assets/images/icons/oameni.svg" alt="Zilieri" v-if="tractor.machine_name == 'ZILIER_MACHINE'" />
                    <img class="machine-image" src="@/assets/images/icons/tractor.svg" alt="Tractor" v-else />
                    <div class="machine-number">{{tractor.machine_name == 'ZILIER_MACHINE' ? 'Zilieri(' + tractor.datallers_count + ')' : tractor.machine_name}}</div>
                    <div class="machine-line"></div>
                  </div>
                  <div class="utility-container" v-if="tractor.equipments && tractor.equipments.length > 0">
                    <img class="utility-image" src="@/assets/images/icons/utilaj.svg" alt="Utilaj" />
                    <div class="utility-text">{{tractor.equipments.map(e => e.name).join(', ')}}</div>
                  </div>
                  <div class="culture-container" v-if="tractor.cultures && tractor.cultures.length > 0">
                    <img class="culture-image" src="@/assets/images/icons/culture.svg" alt="Cultura" />
                    <div class="culture-text">{{tractor.cultures.join(', ')}}</div>
                  </div>
                  <div class="parcel-container">
                    <img class="parcel-image" src="@/assets/images/icons/parcel2.svg" alt="Parcele" />
                    <div class="parcel-text">{{tractor.parcel_names.map(p => p.name).join(' / ')}}</div>
                  </div>
                  <div class="products-container" v-if="tractor.batch_items && tractor.batch_items.length > 0">
                    <img class="products-image" src="@/assets/images/icons/droplet-simple-green.svg" alt="Produse" />
                    <div class="products-text">
                      <div v-for="(batch, b_idx) in tractor.batch_items" :key="b_idx">
                        <span>{{batch.product_name}}</span>
                        <span class="products-text-light">({{parseFloat(batch.dose_per_ha).toFixed(2)}} {{batch.mu}}/ha, total cantitate {{ parseFloat(batch.parcel_quantity).toFixed(2) }} {{batch.mu}})</span>
                      </div>
                    </div>
                  </div>
                  <div class="cost-container">
                    Cost (ron): {{(parseFloat( tractor.batch_cost) + parseFloat(tractor.datallers_salary) ).toFixed(2)}}
                  </div>
                  <div class="work-notes" v-if="tractor.notes && tractor.notes.length > 0">
                    <p>Observații</p>
                    {{tractor.notes.join(', ')}}
                  </div>
              </div>
            </div>
          </DxScrollView>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import DxPopup from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "WorkDetails",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
   work_uuid: {
      type: String,
      default: "",
    },
     work_name: {
      type: String,
      default: "",
    },
    work_date: {
      type: String,
      default: "",
    },
    parcel_uuid: {
      type: String,
      default: "",
    },
     department_uuid: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      workDetailsResult: [],
      scrollAttributes: {
                class: 'work-details-scroll'
            },
    };
  },
  components: {
    DxPopup,
    DxScrollView,
  },
  computed: {
     ...mapGetters({
      currentDepartment: "auth/currentDepartment",
    }),

  },
  watch: {
    showPopup(newVal){
      if(newVal){
          this.getSpcWorkDetails({
            //department_uuid: this.currentDepartment.department_uuid,
            department_uuid:this.department_uuid,
            work_uuid: this.work_uuid,
            work_date: this.work_date,
            parcel_uuid: this.parcel_uuid
          }).then(response => {
            this.workDetailsResult = [];

            response.forEach(element => { // create TRACTORS

              let tractorInfo = {
                parcel_names: [],
                equipments: [],
                cultures: [],
                batch_items: [],
                batch_cost: 0,
                datallers_count: 0,
                datallers_salary : 0,
                notes: []
              } ;
              if(element.length > 0){
                tractorInfo.machine_uuid = element[0].machine.uuid;
                tractorInfo.machine_name = element[0].machine.registration_number ? element[0].machine.registration_number : element[0].machine.name;
                tractorInfo.datallers_count = element[0].datallers_count;
                tractorInfo.datallers_salary = element[0].dataller_total_salary;

                element.forEach(parcel => { // CREATE PARCEL LIST
                  let notes_check = tractorInfo.notes.find(obj => obj == parcel.notes);
                   if(notes_check === undefined) {
                    tractorInfo.notes.push(parcel.notes);
                  }

                  let check_parcel = tractorInfo.parcel_names.find(obj => obj.uuid == parcel.parcel.uuid);
                  if(check_parcel === undefined) {
                    tractorInfo.parcel_names.push({uuid: parcel.parcel.uuid, name: parcel.parcel.name });
                  }

                  if(parcel.equipments && parcel.equipments.length > 0){
                    parcel.equipments.forEach(equip => {
                      let check_equip = tractorInfo.equipments.find(obj => obj.uuid == equip.uuid);
                      if(check_equip === undefined){
                        tractorInfo.equipments.push(equip);
                      }
                    });
                  }

                  
                  parcel.batch_item.forEach(batch => { //CREATE BATCH ITEMS
                    let check_batch =  tractorInfo.batch_items.find(obj => obj.product_uuid == batch.product_uuid);
                    if(check_batch === undefined){

                      tractorInfo.batch_cost = Number.parseFloat(tractorInfo.batch_cost) + Number.parseFloat(batch.unit_price);

                      tractorInfo.batch_items.push({
                        product_uuid: batch.product_uuid,
                        product_name: batch.product_name,
                        dose_per_ha: batch.dose_per_ha,
                        mu: batch.measure_unit.name,
                        price: batch.unit_price,
                        quantity: Number.parseFloat(batch.quantity).toFixed(2),
                        net_surface_worked: parcel.net_surface_worked,
                        parcel_quantity:(batch.dose_per_ha*parcel.net_surface_worked).toFixed(2)
                      });
                    }
                    else{
                      check_batch.parcel_quantity =  Number.parseFloat(check_batch.parcel_quantity) +  Number.parseFloat(batch.dose_per_ha*parcel.net_surface_worked);
                    }
                  });

                })
              }
              this.workDetailsResult.push(tractorInfo);
            });
            
          });
      }
    }
  },
  methods: {
     ...mapActions({
      getSpcWorkDetails: "seasonOnParcelHasCulture/getSpcWorkDetails"
    }),
    buttonCloseEvent() {
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/digitalAgenda/daBatchProductList.scss";
@import "@/assets/scss/digitalAgenda/daWorkDetails.scss";
</style>
