const getters = {
  importTemplate: state => state.import_template,
  importTemplates: state => state.import_templates,
  requiredAttributes: state => state.required_attributes,
  importConfigurations: state => state.import_configurations,
  importSourceCredentials: state => state.import_source_credentials,
  importValidatedData: state => state.import_validated_data,
}

export default getters;
