<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="import-settings-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3 v-if="modalData && modalData.import_ref">{{ $t('tools.configurator.' +
            lineToUnderscore(modalData.import_ref)) }}</h3>
        </div>
        <div class="modal-body" style="overflow-y: auto; overflow-x: hidden;">
          <div class="row" style="padding-left: 5px;">
            <Toggle :toggleText="$t('tools.configurator.import_settings.manual_import_is')"
              :isActive="is_manual_import_active" :onChange="handleToggleManual">
            </Toggle>
            <div class="col-lg-11">
              <div class="separator-line"></div>
            </div>
            <ImportEmailSettings ref="emailSettingsRef" :emailConfig="email_import_config"
              :emailCredentials="email_credentials" @onEmailDataChanged="setEmailConfigData">
            </ImportEmailSettings>
            <div class="col-lg-11">
              <div class="separator-line"></div>
            </div>
            <Toggle :toggleText="$t('tools.configurator.import_settings.api_import_is')"
              :isActive="is_api_import_active" :disabled="true" :onChange="handleToggleApi">
            </Toggle>
          </div>
        </div>
        <div class="modal-footer">
          <div class="justify-content-end" style="margin-bottom: 0px;">
              <button class="btn btn-success" style="margin-right: 15px;" @click="finish()">{{ $t('common.save') }}</button>
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{ $t('common.close')}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { lineToUnderscore, successMessage, errorMessageList } from "~/helpers/common";
import Toggle from "@/components/Toggle.vue";
import ImportEmailSettings from "./ImportEmailSettings.vue";
import { mapActions, mapGetters } from "vuex";
import { sourceTypes } from "@/helpers/ImportSourceTypes"

export default {
  name: 'ImportSettingsDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data ? this.data : {},
      import_configs: this.importConfigurations ? this.importConfigurations : [],
      source_credentials: this.importSourceCredentials ? this.importSourceCredentials : [],
      email_import_config: {},
      api_import_config: {},
      manual_import_config: {},
      email_credentials: {},
      api_credentials: {},
      is_manual_import_active: false,
      is_api_import_active: false,
      email_import_data: {},
    };
  },
  computed: {
    ...mapGetters({
      importConfigurations: 'importConfig/importConfigurations',
      importSourceCredentials: 'importConfig/importSourceCredentials',
    }),
  },
  components: {
    Toggle,
    ImportEmailSettings
  },
  methods: {
    ...mapActions({
      getImportConfigurations: 'importConfig/getImportConfigurations',
      getImportSourceCredentials: 'importConfig/getImportSourceCredentials',
      updateImportConfiguration: 'importConfig/updateImportConfiguration',
    }),
    disableBodyScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableBodyScroll() {
      document.body.style.overflow = '';
    },
    checkEmailDataValidation() {
      return this.$refs.emailSettingsRef.validate();
    },
    close: function () {
      this.$emit('close');
      this.enableBodyScroll();
      this.email_import_config = {};
      this.api_import_config = {};
      this.manual_import_config = {};
    },
    finish() {
      let vm = this;
      if (this.checkEmailDataValidation()) {
        let data_to_update = {
          manual: this.manual_import_config,
          api: this.api_import_config,
          email: {
            config: this.email_import_config,
            source_credential: {
              uuid: this.email_import_data.selected_email_credential ? this.email_import_data.selected_email_credential : null,
              type: sourceTypes.EMAIL_IMPORT.id,
              config: JSON.stringify({
                username: this.email_import_data.credential_user_name,
                password: this.email_import_data.credential_password,
                host: this.email_import_data.credential_host,
                port: this.email_import_data.credential_port,
                encryption: this.email_import_data.credential_encryption,
              }),
            },
            source_config: {
              uuid: this.email_import_data.selected_credential_configuration ? this.email_import_data.selected_credential_configuration : null,
              credential_uuid: this.email_import_data.selected_email_credential ? this.email_import_data.selected_email_credential : null,
              config: JSON.stringify({
                subject: this.email_import_data.configuration_subject,
                attachment_file_name: this.email_import_data.configuration_attachment_file_name,
                subject_match: this.email_import_data.configuration_subject_match,
                attachment_file_name_match: this.email_import_data.configuration_attachment_file_name_match,
                email_from: this.email_import_data.configuration_email_from,
                attachment_file_extensions: ['csv', 'txt', 'xls', 'xlsx'],
              }),
            },
          },
        };
        this.updateImportConfiguration(data_to_update).then((response) => {
          successMessage(vm, response.message);
          vm.loadImportSourceCredentials();
          this.$emit('onOK');
          this.close();
        }).catch((e) => {
          errorMessageList(vm, e.errors);
        });
      }
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
    handleToggleApi(is_checked) {
    },
    handleToggleManual(is_checked) {
      this.is_manual_import_active = is_checked;
      this.manual_import_config.is_active = is_checked ? 1 : 0;
    },
    getSelectedImportConfig(source_type) {
      let config_default = {
        uuid: null,
        source_config_uuid: null,
        name: this.modalData.import_id,
        source_type: source_type.id,
        import_type: this.modalData.import_id,
        is_active: 0
      };
      if (this.modalData && this.import_configs && source_type) {
        return this.import_configs.find(config => config.import_type === this.modalData.import_id && config.source_type === source_type.id) || config_default;
      }

      return config_default;
    },
    getSelectedImportCredentials(source_type) {
      if (this.source_credentials && source_type) {
        return this.source_credentials.filter(credential => credential.type === source_type.id);
      }
      return {};
    },
    async loadImportConfigurations() {
      this.getImportConfigurations();
    },
    async loadImportSourceCredentials() {
      this.getImportSourceCredentials();
    },
    setEmailConfigData(data) {
      this.email_import_data = data;
    }
  },
  mounted() {
    this.loadImportConfigurations();
    this.loadImportSourceCredentials();
  },
  beforeDestroy() {
    this.enableBodyScroll(); // Ensure scrolling is enabled when the component is destroyed
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.modalData = newVal;
      },
      deep: true,
    },
    show(newVal) {
      if (newVal) {
        // import configurations
        this.email_import_config = { ...this.getSelectedImportConfig(sourceTypes.EMAIL_IMPORT) };
        this.api_import_config = { ...this.getSelectedImportConfig(sourceTypes.API_IMPORT) };
        this.manual_import_config = { ...this.getSelectedImportConfig(sourceTypes.MANUAL_IMPORT) };

        this.is_manual_import_active = this.manual_import_config ? this.manual_import_config.is_active === 1 : false;

        this.disableBodyScroll();
      }
    },
    importConfigurations: {
      handler: function (newVal) {
        this.import_configs = newVal;
      },
      deep: true,
    },
    importSourceCredentials: {
      handler: function (newVal) {
        this.source_credentials = newVal;
        // credentials
        this.email_credentials = this.getSelectedImportCredentials(sourceTypes.EMAIL_IMPORT);
        this.api_credentials = this.getSelectedImportCredentials(sourceTypes.API_IMPORT);
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.modal-container {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  height: 90%;
  width: 900px;
}

.modal-header,
.modal-footer {
  height: 50px;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.separator-line {
  border-bottom: 1px solid grey;
  margin-bottom: 15px;
}
</style>
