<template>
  <div class="validator-holder" v-if="true">
    <card
      :title="$t('tools.import_validator.title')"
      :title-bottom-padding="'10px'"
      id="import-validator"
    >
      <DxDataGrid
        id="gridContainer"
        ref="dataGrid"
        keyExpr="uuid"
        :data-source="gridData"
        :columns="gridColumns"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :filter-row="{ visible: true, applyFilter: 'auto' }"
        :selection="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
        :column-min-width="150"
        :selected-row-keys="selectedRowKeys"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :repaint-changes-only="true"
        @selection-changed="onSelectionChanged($event)"
        @saving="onSaving"
        @exporting="onExporting"
        @row-prepared="onRowPrepared"
        :height="750"
      >
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="true" :pageSize="100" />
        <DxPager
          :visible="true"
          :showPageSizeSelector="true"
          :allowedPageSizes="[23, 100, 500]"
          :showInfo="true"
        />
        <DxSearchPanel :visible="true" :width="240" />
        <DxSelection
          select-all-mode="allMode"
          show-check-boxes-mode="always"
          mode="multiple"
        />
        <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="true"
          :select-text-on-edit-start="true"
          :start-edit-action="'click'"
          mode="batch"
        />
        <DxLoadPanel
          :position="position"
          v-model:visible="isLoading"
          :show-indicator="true"
          :show-pane="true"
          :shading="true"
          :hide-on-outside-click="false"
          :on-shown="onShownLoadPanel"
          :on-hidden="onHiddenLoadPanel"
          shading-color="rgba(0,0,0,0.4)"
          message="Updating..."
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxGroupPanel :visible="true" />
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          :fileName="'ValidatorDataGridExport'"
        />
        <DxColumnChooser :enabled="true" />
        <DxColumnFixing :enabled="true" />
        <DxToolbar>
          <DxItem name="groupPanel" />
          <DxItem name="columnChooserButton" />
          <DxItem name="exportButton" />
          <DxItem name="revertButton" />
          <DxItem name="saveButton" />
          <DxItem name="searchPanel" />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'trash',
              text: 'Delete selected rows (' + selectedCount + ')',
              onClick: confirmDelete,
            }"
          />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'edit',
              text: 'Edit selected rows (' + selectedCount + ')',
              onClick: onClickShowEditModal,
            }"
          />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'clear',
              text: 'Clear Selection (' + selectedCount + ')',
              onClick: clearSelection,
            }"
          />
        </DxToolbar>
      </DxDataGrid>
      <div
        class="row"
        style="
          width: 250px;
          margin-top: 35px;
          margin-left: 15px;
          margin-bottom: 15px;
        "
      >
        <button class="btn-default" @click="goBack">
          {{ $t("back_to_list") }}
        </button>
      </div>
    </card>
    <DeleteConfirmationDialog
      :show="showDeleteConfirmationModal"
      @close="showDeleteConfirmationModal = false"
      @confirm="deleteSelectedRows"
      :title="$t('tools.import_validator.title')"
      :message="$t('tools.import_validator.delete_from_validated_data')"
    ></DeleteConfirmationDialog>
    <MultipleRowsEditDialog
      :show="showEditModal"
      @close="showEditModal = false"
      @confirm="editSelectedRows"
      :departments="this.getCellDepartments()"
      :seasons="this.getCellSeasons()"
      :work_process="this.getUniqueWorkProcess()"
      :person="this.getUniquePerson()"
      :title="$t('tools.import_validator.title')"
    >
    </MultipleRowsEditDialog>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxScrolling,
  DxLoadPanel,
  DxDataGridTypes,
  DxToolbar,
  DxItem,
  DxPaging,
  DxPager,
  DxSearchPanel,
  DxEditing,
  DxHeaderFilter,
  DxGroupPanel,
  DxExport,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";
import { successMessage, errorMessageList } from "~/helpers/common";
import { getValidatorGridColumns } from "./gridColumns";
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";
import MultipleRowsEditDialog from "./MultipleRowsEditDialog";

export default {
  name: "ImportValidator",
  components: {
    DxDataGrid,
    DxScrolling,
    DxLoadPanel,
    DxDataGridTypes,
    DxToolbar,
    DxItem,
    DxButton,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxEditing,
    DxHeaderFilter,
    DxGroupPanel,
    DxExport,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DeleteConfirmationDialog,
    MultipleRowsEditDialog,
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedCount: 0,
      isLoading: false,
      gridData: this.importValidatedData ? this.importValidatedData : [],
      showDeleteConfirmationModal: false,
      showEditModal: false,
      gridColumns: [],
      position: { of: '#import-validator' },
    };
  },
  computed: {
    ...mapGetters({
      importValidatedData: "importConfig/importValidatedData",
      seasons: "seasons/seasons",
      departments: "department/departments",
    }),
    dataGridInstance: function () {
      return this.$refs.dataGrid.instance;
    },
  },
  methods: {
    ...mapActions({
      getImportValidatedData: "importConfig/getImportValidatedData",
      updateImportValidatedData: "importConfig/updateImportValidatedData",
      getSeasons: "seasons/getSeasons",
      getDepartments: "department/getDepartments",
    }),
    async loadImportValidatedData(data_import_id) {
      this.getImportValidatedData({ data_import_id: data_import_id });
    },
    getGridColumns() {
      const columns = getValidatorGridColumns(
        this.getCellDepartments(),
        this.getCellSeasons()
      );
      columns.map((column) => {
        if (column.caption) {
          column.caption = this.$t("tools.import_validator." + column.caption);
        }
        return column;
      });

      return columns;
    },
    formatDateColumns(changes) {
      changes.forEach((change) => {
        if (change.data) {
          if (change.data.start_date) {
            change.data.start_date = format(
              new Date(change.data.start_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
          if (change.data.end_date) {
            change.data.end_date = format(
              new Date(change.data.end_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
        }
      });
    },
    async updateImportValidatedDataWithCallback(changes) {
      try {
        const response = await this.updateImportValidatedData({ changes });
        successMessage(this, response.message);
        return true;
      } catch (e) {
        errorMessageList(this, e.errors);
        return false;
      }
    },
    onSaving(e) {
      if (e.changes.length) {
        this.formatDateColumns(e.changes);
        this.updateImportValidatedDataWithCallback(e.changes).then((result) => {
            if (result) {
              e.component.refresh();
            }
        });
      }
    },
    async confirmDelete() {
      if (this.selectedRowKeys?.length > 0) {
        this.showDeleteConfirmationModal = true;
      }
    },
    async deleteSelectedRows() {
      this.showDeleteConfirmationModal = false;
      if (this.selectedRowKeys.length > 0) {
        const changes = [];
        this.selectedRowKeys.forEach((key) => {
          changes.push({ type: "remove", key: key });
        });

        this.updateImportValidatedDataWithCallback(changes).then((result) => {
          if (result) {
            if (this.dataGridInstance) {
              const dataSource = this.dataGridInstance.getDataSource();
              if (dataSource) {
                const store = dataSource.store();
                if (store) {
                  for (const key of this.selectedRowKeys) {
                    try {
                      store.remove(key);
                    } catch (error) {
                      console.error(
                        `Failed to remove item with key ${key}:`,
                        error
                      );
                    }
                  }
                }
                dataSource.reload();
              }
            }
            this.clearSelection();
          }
        });
      }
    },
    onSelectionChanged(e) {
      this.selectedRowKeys = e.selectedRowKeys;
      this.selectedCount = e.selectedRowKeys.length;
    },
    clearSelection() {
      this.selectedRowKeys = [];
    },
    onExporting(e) {
      e.fileName =
        "SGPS_DASC_DataGridExport_" + format(new Date(), "yyyy_MM_dd_hh_mm_ss");
      //const dataRows = e.component.getDataSource().items();
      //e.cancel = true; // Prevent default export
    },
    onRowPrepared(event) {
      if (event.rowType === 'data') {
        const rowData = event.data;
        let backgroundColor;
        if (rowData.status && rowData.status.color) {
          event.rowElement.style.backgroundColor = rowData.status.color;
        }
        /*if (!rowData.parcel_name) {
          backgroundColor = '#FF0000'; // Red for missing parcel_name
        } else {
          backgroundColor = '#008000'; // Green for present parcel_name
        }*/
        if(backgroundColor) {
          event.rowElement.style.backgroundColor = backgroundColor;
          event.rowElement.style.color = this.getContrastingTextColor(backgroundColor);
        }
      }
    },
    getContrastingTextColor(backgroundColor) {
      const color = backgroundColor.charAt(0) === '#' ? backgroundColor.substring(1, 7) : backgroundColor;
      const r = parseInt(color.substring(0, 2), 16);
      const g = parseInt(color.substring(2, 4), 16);
      const b = parseInt(color.substring(4, 6), 16);
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return luminance > 0.5 ? '#000000' : '#FFFFFF'; // Black text for light backgrounds, white text for dark backgrounds
    },
    goBack() {
      this.$router.back();
    },
    getCellDepartments() {
      return (
        this.departments
          /*.filter(department => department.type === 'company')*/
          .map((value) => ({
            department: value.name,
            department_uuid: value.uuid,
          }))
      );
    },
    getCellSeasons() {
      return this.seasons.map((value) => ({
        season: value.name,
        season_uuid: value.uuid,
      }));
    },
    getUniqueWorkProcess() {
      const work_process = [...new Set(this.gridData.map((value) => value.work_process))];
      return work_process.map((process, index) => ({
        value: index + 1,
        label: process,
      }));
    },
    getUniquePerson() {
      const person = [...new Set(this.gridData.map((value) => value.person))];
      return person.map((person, index) => ({
        value: index + 1,
        label: person,
      }));
    },
    onClickShowEditModal() {
      if (this.dataGridInstance.getSelectedRowKeys().length > 0) {
        this.showEditModal = true;
      }
    },
    editSelectedRows(department_uuid, season_uuid, work_process, person) {
      this.showEditModal = false;

      const selectedRowKeys = this.dataGridInstance.getSelectedRowKeys();
      const dataSource = this.dataGridInstance.getDataSource();

      if (
        selectedRowKeys.length === 0 ||
        !dataSource ||
        (!department_uuid && !season_uuid && !work_process && !person)
      ) {
        return;
      }

      const store = dataSource.store();
      if (!store) {
        return;
      }

      const changes = [];
      selectedRowKeys.map((key) => {
        let updateItemData = {};
        if (department_uuid) {
          updateItemData.department_uuid = department_uuid;
        }
        if (season_uuid) {
          updateItemData.season_uuid = season_uuid;
        }
        if (work_process) {
          updateItemData.work_process = work_process;
        }
        if (person) {
          updateItemData.person = person;
        }
        changes.push({ type: "update", key: key, data: updateItemData });
      });

      this.onShownLoadPanel();

      this.updateImportValidatedDataWithCallback(changes).then((result) => {
        if (result) {
          // Update the data in bulk using the store
          const updatePromises = selectedRowKeys.map((key) => {
            return store.byKey(key).then((rowData) => {
              if (rowData) {
                if (department_uuid) {
                  rowData.department_uuid = department_uuid;
                }
                if (season_uuid) {
                  rowData.season_uuid = season_uuid;
                }
                if (work_process) {
                  rowData.work_process = work_process;
                }
                if (person) {
                  rowData.person = person;
                }
                return store.update(key, rowData);
              }
            });
          });

          // Wait for all updates to complete
          Promise.all(updatePromises)
            .then(() => {
              // Refresh the DataGrid to reflect the changes
              this.dataGridInstance.refresh();
            })
            .catch((error) => {
              console.error("Error updating rows:", error);
            })
            .finally(() => {
              this.onHiddenLoadPanel();
            });
        }
      });
    },
    async onShownLoadPanel() {
      setTimeout(() => {
        this.isLoading = true;
      }, 10);
    },
    async onHiddenLoadPanel() {
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    },
  },
  async mounted() {
    if (this.$route.query.import_id) {
      this.getDepartments();
      this.getSeasons();
      this.loadImportValidatedData(this.$route.query.import_id);
    }
  },
  watch: {
    importValidatedData: {
      handler: function (newVal, oldVal) {
        this.gridData = newVal;
      },
      deep: true,
    },
    departments: {
      handler: function (newVal, oldVal) {
        this.gridColumns = this.getGridColumns();
      },
      deep: true,
    },
    seasons: {
      handler: function (newVal, oldVal) {
        this.gridColumns = this.getGridColumns();
      },
      deep: true,
    },
  },
};
</script>

<style>
#gridContainer {
  height: 960px;
}

#import-validator {
  padding: 15px;
}

.btn-default {
  background-color: #eeeded;
  color: #000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;

  &:hover {
    background-color: rgb(223, 221, 221);
    box-shadow: 0 0 8px rgba(223, 221, 221, 0.3);
  }
}

.selected-data {
  margin-top: 20px;
  padding: 17px;
  background-color: rgba(191, 191, 191, 0.15);
}

​ .selected-data .caption {
  font-weight: bold;
  font-size: 115%;
  margin-right: 4px;
}

.import-action-btn {
  margin-left: 20px;
  margin-top: -6px;
  float: right;
}

.disabled-btn {
  background-color: gray !important;
  cursor: not-allowed;
}
</style>
