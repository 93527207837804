import * as types from "../../mutation-types";

const mutations = {
  [types.SET_PARCELS]: (state, payload) => {
    state.parcels = payload;
  },
  [types.SET_PARCEL]: (state, payload) => {
    state.parcel = payload;
  },
  [types.ADD_PARCEL_ITEM]: (state, payload) => {
    state.parcels.push(payload);
  },
  [types.UPDATE_PARCEL_ITEM]: (state, payload) => {
    state.parcels.splice(state.parcels.indexOf(
      state.parcels.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_PARCEL_ITEM]: (state, payload) => {
    state.parcels.splice(state.parcels.indexOf(
      state.parcels.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_PARCEL_FILTERS]: (state, payload) => {
    state.parcel_filters = payload;
  },
}

export default mutations;
