<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('works.parcels.name')" id="parcel-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")' :hide-add="true">
        <parcel-filters
            :limit="limit"
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import ParcelFilters from "~/pages/works/parcels/partials/ParcelFilters.vue";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'Parcels',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      limit: 25,
      filters: this.parcelFilters ? this.parcelFilters : {},
      items: this.parcels ? this.parcels : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    ParcelFilters,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      parcelFilters: 'works-parcels/parcelFilters',
      parcels: 'works-parcels/parcels',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    config() {
      return {
        permission: permissions.PARCELS,
        permission_type: permissionTypes.module,
        edit: {
          url: '/works/parcels/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          disableIf: this.disableDeleteIf,
          disableMessage: this.$t('works.parcels.cannot_be_deleted')
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('common.name'), col: 'name', width: '15%', sortable: true, },
        { name: this.$t('works.parcels.surface'), col: 'surface', align: 'center', width: '14%', sortable: true},
        { name: this.$t('works.parcels.data_input'), col: 'input_type', align: 'center', width: '10%'},
        { name: this.$t('department'), col: 'department.name', align: 'center', width: '15%'},
        { name: this.$t('season'), col: 'season', align: 'center', width: '15%'},
        { name: this.$t('works.parcels.culture'), col: 'culture', key: 'culture_uuid', align:'center', width: '10%'},
        { name: this.$t('common.last_update'), col: 'updated_at', align:'center', width: '12%', sortable: true },
      ];
    },
  },
  methods: {
    ...mapActions({
        deleteObject: 'works-parcels/deleteParcel', //check to delete if has stock
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("works-parcels/"+type.SET_PARCEL_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: '/works/parcels/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      //check if has stock
      this.items.splice(
        this.items.indexOf(
          this.items.find(it => it.uuid === item.uuid)
        ), 1
      );
    },
    disableDeleteIf(row) {
      return !row.is_deletable;
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onSortBy(data) {
      this.parcelFilters.sortBy = data.sortBy;
      this.parcelFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    }
  },
  watch: {
    parcels: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    parcelFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
