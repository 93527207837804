<template>
  <div class="boolean-control">
    <label class="control-label font-weight-bold">{{ data.name }}</label>
    <b-form-select
      v-model="value"
      class="form-control"
      :options="list_values_ddl"
      @option:selected="onItemSelected"
      :class="{ 'field-error' : this.$v.value.$error }"
      v-if="data.type === 'ddl'"></b-form-select>
    <v-select
      v-else
      v-model="value"
      class=""
      placeholder="-"
      autocomplete="off"
      :class="{ 'field-error' : this.$v.value.$error }"
      @option:selected="onItemSelected"
      taggable
      :options="list_values_auto"></v-select>
  </div>
</template>

<script>
import {buildDefaultAutocompleteList, convertDropdown, getServerSubdomain} from "@/helpers/common";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import {mapGetters} from "vuex";

export default {
  name: 'ListControl',
  props: ['data', 'size'],
  data() {
    return {
      value: '',
      getter_values: [],
      controlData: this.data ? this.data : {},
      endpointListValues: this.customSettingValues ? this.customSettingValues : [],
    }
  },
  computed: {
    ...mapGetters({
          customSettingValues: 'settings/customSettingValues'
      }),
    list_values() {
        if(this.controlData.endpoint) {
            return this.endpointListValues;
        }

      return this.controlData.list_values ?? [];
    },
    list_values_auto() {
      return buildDefaultAutocompleteList(this.list_values);
    },
    list_values_ddl() {
      return convertDropdown(this.list_values);
    },
  },
  methods: {
    onItemSelected(data) {
        this.$emit('onItemSelected', data);
    },
    validate() {
      this.$v.value.$touch();
      return !this.$v.value.$invalid;
    }
  },
  mounted() {
    if(this.data.endpoint) {
        this.$store.dispatch('settings/getSettingValuesByEndpoint', {
            endpoint: this.data.endpoint
        }).then((resp) => {
          this.$set(this, 'value', {
            value: this.controlData?.value,
            label: resp.data.find(c => c.uuid === this.controlData.value)?.name
          });
        });
    } else {
      if(this.data.list_values) {
          this.$set(this, 'value', {
            value: this.controlData?.value,
            label: this.data.list_values.find(c => c.uuid === this.controlData.value)?.name
          });
      } else {
        this.$set(this, 'value', this.data.value);
      }
    }
    this.$set(this.controlData, 'validate', this.validate);
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.controlData = newVal;
      },
      deep: true,
      immediate: true,
    },
    value: function(newVal) {
      this.$emit('onChange', newVal?.value);
    },
    customSettingValues: {
      handler: function(newVal) {
        this.endpointListValues = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(function(val) {
          return this.data.is_required === 1;
        })
      },
    }
  },
}
</script>
