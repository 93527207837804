<template>
  <div class="edit-role-item row">
    <div class="col-lg-4">
      <label class="control-label">
        <input type="checkbox" v-model="item.selected" @change="selectItem"/> {{ item.name }}
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="item.access.r" class="mr-2"
               :disabled="!item.selected"/>{{$t("common.read")}}
      </label>
    </div>
    <div class="col-lg-3">
      <label>
        <input type="checkbox" v-model="item.access.w" class="mr-2"
               :disabled="!item.selected"/>{{$t("common.create")}}/{{$t("common.update")}}
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="item.access.x"
               class="mr-2" :disabled="!item.selected"/> {{$t("common.delete")}}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
      name: 'EditRoleItem',
      props: ['data'],
      data() {
          return {
              item: this.data ? this.data : {},
              selected: false,
          }
      },
      computed: {
          localAccess() {
              return Object.assign({}, this.item.access);
          }
      },
      methods: {
          onItemChanged(val) {
              this.$set(this.item, 'selected', this.item.selected);

              if(!this.item.selected) {
                  this.item.access = {r: false, w:false,  x:false};
              }

              this.$emit('onItemChanged', this.item);
          },
          selectItem() {
            if (this.item.selected) { //selected
              this.item.access = {r: true, w: true,  x: true};
            } else {
              this.item.access = {r: false, w: false,  x: false};
            }
          }
      },
      mounted() {
          this.onItemChanged(this.item.selected);

        if (this.data.check_selected !== undefined) {
          this.data.selected = this.data.check_selected;
        }
      },
      watch: {
          data: {
              handler: function(newVal) {
                  this.item = newVal;
              },
              deep: true,
              immediate: true,
          },
      }
  }
</script>
