<template>
    <div class="toggle-container">
        <label :class="{ 'toggle-switch': true, 'is-disabled': isDisabled }">
            <input type="checkbox" v-model="isToggled" @change="handleToggle" :disabled="disabled" />
            <span class="slider round"></span>
        </label>
        <p>{{ toggleText }}: {{ isToggled ? isToggledText : isNotToggledText }}</p>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    props: {
        toggleText: {
            type: String,
            required: true
        },
        toggledText: {
            type: String,
        },
        notToggledText: {
            type: String,
        },
        onChange: {
            type: Function,
            required: true
        },
        isActive: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isDisabled: this.disabled ? this.disabled : false,
            isToggled: this.isActive ? this.isActive : false,
            isToggledText: this.toggledText ? this.toggledText : this.$t("common.active"),
            isNotToggledText: this.notToggledText ? this.notToggledText : this.$t("common.inactive")
        };
    },
    methods: {
        handleToggle() {
            if (typeof this.onChange === 'function') {
                this.onChange(this.isToggled);
            }
        }
    },
    watch: {
        isActive: {
            handler: function(newVal) {
                this.isToggled = newVal;
            },
            deep: true
        }
    }
};
</script>

<style>
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: green;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.is-disabled {
    opacity: 0.4;
}

input:disabled {
    cursor: not-allowed;
}
</style>