<template>
  <div ref="mainRef" :class="colorFilterIsActive ? 'main-map-wrapper-color-filter' :  'main-map-wrapper'">
    <div :class="colorFilterIsActive ? 'map_message_color_filter' : 'map_message'" :style="'--titleTop: ' + css_title_top + 'px'">
      <p class="text-center text-color-filter" v-if="colorFilterIsActive">Stratificare aplicata</p>
      <img class="color-filter-close-icon" v-if="colorFilterIsActive" @click="inactivateColorFilter" src="@/assets/images/icons/dialog-close-gray.svg"/>
      <p class="text-center text-success" v-if="layers_loading">Incărcare hartei in curs ...</p>
      <p class="text-center text-message" v-if="drawingIsActivated">Atingeți pentru a continua desenul.</p>
       <p class="text-center text-message" v-if="drawingIsActivated || editModeIsActive">Suprafața parcelă: {{calculatedSurface}}</p>
      <p class="text-center text-message" v-if="!drawingIsActivated && !layers_loading && !colorFilterIsActive">Anul agricol: {{filterData.season_name}}</p>
      <p v-if="error" class="text-danger">{{ error }}</p>
    </div>
    
    <div class="overlay" v-if="layers_loading"></div>

    <GmapMap
      v-if="showMap && showMapLocal"
      :zoom="zoom_level"
      :center="center"
      :options="mapOptions"
      :map-type-id="map_type"
      ref="mapRef"
      :class="[mapClass,{'gmap-map': true}, {'gmap-map-color-filter': colorFilterIsActive}]"
      @dragend="handleMarkersVisibility"
      @resize="handleMarkersVisibility"
    >
      <GmapInfoWindow
        :options="infoWindow.options"
        :position="infoWindow.position"
        :opened="infoWindow.opened"
        @closeclick="infoWindow.opened = false"
      >
        <div v-html="infoWindow.content"></div>
      </GmapInfoWindow>
      <GmapPolygon
        :ref="'gmp_' + layer.uuid"
        :key="parcelLayers.length + index"
        :clickable="true"
        v-for="(layer, index) in parcelLayers"
        :paths="layer.paths"
        :options="layer.options"
        @click="parcelClick($event, layer)"
        :draggable="layerUUIDForEdit == layer.uuid"
        :geodesic="layerUUIDForEdit == layer.uuid"
        :editable="layerUUIDForEdit == layer.uuid"
      >
      </GmapPolygon>
      <GmapMarker
        :key="index"
        v-for="(layer, index) in parcelLayers"
        :clickable="true"
        :position="layer.marker.position"
        :visible="layer.marker.visibility && zoomLevel > hide_on_zoom_level"
        icon="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
        :label="layer.marker.label"

        @click="parcelClick($event, layer)"
      />
      <GmapMarker v-if="layer.culture"
        :key="(parcelLayers.length * 2) + index"
        v-for="(layer, index) in parcelLayers"
        :clickable="true"
        :position="layer.cultureMarker.position"
        :visible="layer.culture && layer.marker.visibility && zoomLevel > hide_on_zoom_level"
        icon="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
        :label="layer.cultureMarker.label"
        @click="parcelClick($event, layer)"
      />
      <GmapMarker v-if="showScoutinMarkers(layer, 'MARKER')"
        :key="(parcelLayers.length * 3) + index"
        v-for="(layer, index) in parcelLayers"
        :clickable="true"
        :position="layer.scoutingMarker.position"
        :visible="zoomLevel > hide_on_zoom_level"
        icon="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
        :label="layer.scoutingMarker.label"
        @click="parcelClick($event, layer)"
      />
      <GmapMarker v-if="showScoutinMarkers(layer, 'ICON')"
        :key="(parcelLayers.length * 4) + index"
        v-for="(layer, index) in parcelLayers"
        :clickable="true"
        :position="layer.scoutingMarker.position"
        :visible="zoomLevel > hide_on_zoom_level"
        :icon="{url: require('@/assets/images/icons/' + (layer.scoutingMarker.icon_path && layer.scoutingMarker.icon_path.length > 0 ? layer.scoutingMarker.icon_path : 'circle-check-solid.svg')),
                 scaledSize: new google.maps.Size(30, 30),
                 anchor: new google.maps.Point(15, 60)}"
        @click="parcelClick($event, layer)"
      />
    </GmapMap>
    <div v-if="colorFilterIsActive" class="red-border-right"></div>
    <div v-if="colorFilterIsActive" class="red-border-left"></div>
    <div v-if="colorFilterIsActive" class="red-border-bottom"></div>
    <div v-if="colorFilterIsActive && legendIsSimple" class="simple-legend">
      <div class="legend-title">
        <div class="buble-holder">
          <div v-for="(color, index) in colorCultures" :key="index" class="color-buble" :style="bgColor(color)"></div>
        </div>
        <div class="legend-text">Legendă stratificare</div>
      </div>
    </div>
    <div v-if="colorFilterIsActive && !legendIsSimple" class="extended-legend"></div>

    <div class="map-buttons-left" v-show="showMap && showMapLocal"
        :style="'--titleTop: ' + (92 + css_title_top) + 'px'"
        v-if="!selection_mode && !drawingIsActivated && !mapFilterVisibility && !parcelInfoDialogVisibility && !editModeIsActive && !layerColorFilterVisibility">
        <b-button
          class="map_filter_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="showParentModule"
        ><img src="@/assets/images/icons/list.svg"
        /></b-button>
    </div>

     <div class="map-buttons" v-show="showMap && showMapLocal"
      :style="'--titleTop: ' + (92 + css_title_top) + 'px'"
       v-if="!drawingIsActivated && !mapFilterVisibility && !parcelInfoDialogVisibility && !editModeIsActive && !layerColorFilterVisibility">
       
      <b-button
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="showMapFilter"
      ><img src="@/assets/images/icons/options.svg"
      /></b-button>
       <b-button v-if="!editButtonsVisibility"
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="showEditButtons"
      ><img src="@/assets/images/icons/plus_green.svg"
      /></b-button>

      <div v-if="editButtonsVisibility" class="button_horizontal_group">
        <b-button
          class="map_filter_button map_filter_button_left"
          variant="outline-secondary"
          size="sm"
          pill
          @click="loadMapDrawingManager">
            <img src="@/assets/images/icons/plus.svg"/>
        </b-button>
        <div class="vertical_spacer"></div>
        <b-button
          class="map_filter_button map_filter_button_middle"
          variant="outline-secondary"
          size="sm"
          pill
          @click="startEditMode">
            <img src="@/assets/images/icons/edit.svg"/>
        </b-button>
        <div class="vertical_spacer"></div>
        <b-button
          class="map_filter_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="hideEditButtons">
            <img src="@/assets/images/icons/map_draw_cancel_2.svg"/>
        </b-button>
      </div>

      <b-button
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="showLayerColorFilter"
      ><img src="@/assets/images/icons/map_layers.svg"
      /></b-button>
      <b-button
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="goToCurrentLocation"
      ><img src="@/assets/images/icons/current_position.svg"
      /></b-button>
      <b-button v-if="showNewlayerSave"
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="saveDrawedLayer"
      ><img src="@/assets/images/icons/floppy.svg"
      /></b-button>
       <b-button
        class="map_filter_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="refreshLayers"
      ><img src="@/assets/images/icons/refresh_map.svg"
      /></b-button>

      <div class="refresh-icon-holder" v-if="false">
        <div class="r-wrapper rounded-pill c-pointer" @click="refreshLayers">
          <img src="~@/assets/images/icons/refresh_map.svg" class="icon-svg refresh-bt"/>
        </div>
      </div>
    </div>

    <div class="map_drawing_controls" v-if="drawingIsActivated">
        <div class="map_drawing_button" v-if="false">
          <img src="@/assets/images/icons/map_draw_undo.svg"/>
        </div>
        <div class="map_drawing_button map_button_active" v-if="showNewlayerSave" @click="showLayerDetailDialog">
          <img src="@/assets/images/icons/map_draw_save_active.svg"/>
        </div>
        <div class="map_drawing_button" v-else>
          <img src="@/assets/images/icons/map_draw_save_inactive.svg"/>
        </div>
        <div class="separator"></div>
        <div class="map_drawing_button map_drawing_bottom_button map_bottom_button_active" @click="loadMapDrawingManager" v-if="showNewlayerSave">
          <img src="@/assets/images/icons/map_draw_cancel_2.svg"/>
        </div>
        <div class="map_drawing_button map_drawing_bottom_button" @click="loadMapDrawingManager" v-else>
          <img src="@/assets/images/icons/map_draw_cancel.svg"/>
        </div>
    </div>

    <div class="map_drawing_controls" v-if="editModeIsActive">
        <div class="map_drawing_button map_button_active" @click="updateLayer">
          <img src="@/assets/images/icons/map_draw_save_active.svg"/>
        </div>
        <div class="separator"></div>
        <div class="map_drawing_button map_drawing_bottom_button map_bottom_button_active" @click="endEditMode">
          <img src="@/assets/images/icons/map_draw_cancel_2.svg"/>
        </div>
    </div>
    <layer-detail-dialog ref="layerDetailDialog" :showPopup="layerDetailDialogVisibility" @hidden="hideLayerDetailDialog" :layerAddress="newLayerAddress"></layer-detail-dialog>
    <map-filter-dialog
      ref="mapFilterDialog"
      :showPopup="mapFilterVisibility"
      @hidden="hideMapFilter"
      @applyMapFilters="applyMapFilters"
      >
    </map-filter-dialog>
    <layer-color-filter
      ref="layerColorFilter"
      :showPopup="layerColorFilterVisibility" 
      :filterIsActive="colorFilterIsActive"
      :season_uuid="filterData.season_uuid"
      @hidden="hideLayerColorFilter"
      @applyColorFilterByCulture="applyColorFilterByCulture"
      @applyColorFilterByWork="applyColorFilterByWork"
    ></layer-color-filter>
      
     <div
        v-if="false"
        class="save_drawing_layer"
      >
        <b-button
          class="continue_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="loadMapDrawingManager"
          >Cancel</b-button
        >
      </div>
  </div>
</template>

<script>
import {getGoogleMapsAPI} from "gmap-vue";
import MapFilterDialog from "./mapFilterDialog.vue";
import LayerDetailDialog from "./layerDetailDialog.vue";
import LayerColorFilter from "./layerColorFilter.vue";

import {
  layerOptions,
  selectedlayerOtions,
  mapOptions,
  infoWindow,
  hasParcelOptions,
  hasWorksOptions,
  scoutinglayerOtions
} from "@/helpers/googleMapHelper";
import * as type from '../../store/mutation-types'

import { mapGetters, mapActions } from 'vuex'
import $ from 'jquery'
import {getStringOrJoinArray} from "@/helpers/common"
import moment from "moment"

export default {
  name: "GoogleMap",
  props: {
    testMessage: {
      type: String,
    },
    showMap: {
      type: Boolean,
    },
    initMapInMount: {
      type: Boolean,
      default: false
    },
    selection_mode: {
      type: Boolean,
      default: false
    },
    mapClass:String,
    parcel_uuid:String,
    parcelInfoDialogVisibility: {
      type: Boolean,
      default: false
    },
    css_title_top: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      legendIsSimple:true,
      colorFilterIsActive: false,
      layerColorFilterVisibility: false,
      editButtonsVisibility: false,
      editModeIsActive: false,
      layerUUIDForEdit: null,
      showMapLocal: true,
      zoom_level: 15,
      max_zoom_level: 14,
      hide_on_zoom_level: 13,
      zoomIndex: -1,
      showAllMarkers: false,
      map_type: 'hybrid',
      center: {lat: 46.442153888889003, lng: 25.903095555556},
      map: null,
      bounds: null,
      parcelLayers: [],
      sortedParcelLayers: [],
      sorted: false,
      loading: false,
      error: null,
      shapes: [],
      drawingIsActivated: false,
      showSurfaceMessage: false,
      calculatedSurface: '',
      drawingManager: Object,
      drawedLayers: [],
      theNewPoligonLayer: null,
      showNewlayerSave: false,
      //newLayerAddress: { layers: [] },
      newLayerAddress: null,
      layerDetailDialogVisibility: false,
      mapFilterVisibility: false,
      filterData: {
        name:'',
        department_uuids:[],
        season_uuid:'',
        season_name:'',
        culture_uuids:[],
        sub_culture_uuids:[]
      },
      scoutingFilterData: {
        show_scouting: false,
        date_from: '',
        date_to: '',
        priorities: []
      },
      drawingPoints: null,
      currentLocationMarker: null,
      currentLocationWatcher: null,
      currentLocationTimeout: null,
      colorCultures: []
    }
  },
  components: {
    LayerDetailDialog,
    MapFilterDialog,
    LayerColorFilter
  },
  computed: {
    ...mapGetters({
        layers_loading: 'layers/layers_loading',
        layer_season_uuid: 'layers/layer_season_uuid',
        seasons: 'seasons/seasons',
        layer_filters: 'layers/layer_filters',
        departments: 'company/departments',
    }),
    google: getGoogleMapsAPI,
    mapOptions: () => mapOptions,
    infoWindow: () => infoWindow,
    layers() {
      let tmplayers = this.$store.getters['layers/layers'];
      return tmplayers;
    },
    layerWorks() {
      let tmplayerWorks = this.$store.getters['layers/layer_works'];
      return tmplayerWorks;
    },

    zoomLevel() {
      return this.map && this.map.getZoom();
    },
    mapReady() {
      let ready = this.google && this.map !== null;
      if (ready) {
        console.log('Map is ready.');
        //this.$refs.mapRef.setMyLocationEnabled(true);
        if(this.filterData.season_uuid == '' && this.filterData.season_name == ''){
          this.setCurrentSeason();
          if(this.$refs.mapFilterDialog){
            this.$refs.mapFilterDialog.setSeason(this.filterData.season_uuid, this.filterData.season_name);
            this.$refs.mapFilterDialog.applyMapFilterAtMapReady();
          }
        }
        this.$emit('onMapReady', ready);
      } else {
        console.error('Map is not ready!');
      }
      return ready;
    },
    chunkSizeInc(){
      if(this.parcelLayers.length < 200){
        return 2;
      } else {
        return 5;
      }
    },
  },
  methods: {
    legendClick(){
      this.legendIsSimple = !this.legendIsSimple;
    },
    bgColor(culture) {
        return {
            'background-color': culture.color
        };
    },
    showParentModule(){
      this.$emit('showParentModule');
    },
    inactivateColorFilter(){
      this.colorFilterIsActive = false;
      this.parcelLayers.forEach(layer => {
        layer.options = this.getLayerOptions(layer);
      });
    },
    getColorFilterOption(color){
      return {
        fillColor: color,
        strokeColor: '#000000',
        strokeWeight: 2,
        strokeOpacity: 1,
        fillOpacity: 0.8,
      }
    },
    applyColorFilter(checkboxCollection, colorByType){
      if(colorByType == 'CULTURE'){
        this.applyColorFilterByCulture(checkboxCollection);
      }
      if(colorByType == 'WORK'){
        this.applyColorFilterByWork(checkboxCollection);
      }
    },
    applyColorFilterByCulture(cultures){
      this.colorCultures = cultures;
      this.colorFilterIsActive = true;
      this.parcelLayers.forEach(layer => {
        if(layer.culture){
          let colorCulture = cultures.find(c => c.uuid == layer.culture.uuid);
          if(colorCulture.selected){
            layer.options = this.getColorFilterOption(colorCulture.color);
          }
          else{
            layer.options = this.getColorFilterOption('#D3D3D3');
          }
        }
        else{
          layer.options = this.getColorFilterOption('#D3D3D3');
        }
      });
    },
    applyColorFilterByWork(work, parcels){
      this.colorFilterIsActive = true;
      this.parcelLayers.forEach(layer => {
        if(parcels.find(p => p.uuid == layer.parcel_uuid )){
          layer.options = this.getColorFilterOption(work.color);
        }
        else{
          layer.options = this.getColorFilterOption('#D3D3D3');
        }
      });
    },
    showLayerColorFilter(){
      this.layerColorFilterVisibility = true;
    },
    hideLayerColorFilter(){
      this.layerColorFilterVisibility = false;
    },
    showEditButtons(){
      this.editButtonsVisibility = true;
    },
    hideEditButtons(){
      this.editButtonsVisibility = false;
    },
    startEditMode(){
      this.editModeIsActive = true;
    },
    endEditMode(){
      this.resetLayerByUUID(this.layerUUIDForEdit);
      this.editModeIsActive = false;
      this.layerUUIDForEdit = null;
    },
    resetLayerByUUID(pLayerUUID){
      if(pLayerUUID && pLayerUUID.length > 0){
          if(this.$refs['gmp_' + pLayerUUID] && this.$refs['gmp_' + pLayerUUID].length > 0){
            let oldPolygon = this.$refs['gmp_' + pLayerUUID][0].$polygonObject;
            let oldPoligonLayer = this.parcelLayers.find(l => l.uuid == pLayerUUID);
            if(oldPolygon && oldPoligonLayer ){
              oldPolygon.setPaths(oldPoligonLayer.paths);
            }

          }
        }
    },
    showScoutinMarkers(layer, info){
      if(
        this.scoutingFilterData.show_scouting &&
        layer.last_active_scouting &&
        (this.scoutingFilterData.date_from.length == 0 || moment(this.scoutingFilterData.date_from) <= moment(layer.last_active_scouting.reported_at)) &&
        (this.scoutingFilterData.date_to.length == 0 || moment(this.scoutingFilterData.date_to) >= moment(layer.last_active_scouting.reported_at.substring(0,10))) &&
        (this.scoutingFilterData.priorities.length == 0 || this.scoutingFilterData.priorities.filter(p => p == layer.last_active_scouting.priority).length > 0 )
      ){
          return true;
      }
      return false;
    },
    applyMapFilters(filterData, scoutingFilterData){
      this.$store.commit("layers/"+type.SET_LAYER_SEASON, filterData.season_uuid);
      this.hideMapFilter();
      this.filterData = filterData;
      this.scoutingFilterData = scoutingFilterData;
      this.refreshLayers();
    },
    showLayerDetailDialog(){
      this.layerDetailDialogVisibility = true;

    },
    hideLayerDetailDialog(refreshLayers, department_uuid, season_uuid, season_name){
      this.layerDetailDialogVisibility = false;
      if(refreshLayers){
        if(department_uuid && season_uuid){
          this.filterData.department_uuids = [];
          this.filterData.department_uuids.push(department_uuid);
          this.filterData.season_uuid = season_uuid;
          this.filterData.season_name = season_name;
        }
        this.loadMapDrawingManager();
        this.refreshLayers();
      }
    },
    showMapFilter(){
      this.mapFilterVisibility = true;
    },
    hideMapFilter(){
      this.mapFilterVisibility = false;
    },
    setLoad(load){
      this.loading = load;
    },
    showFilters() {
      this.$emit("showMapFilter");
    },
    getInitZoom() {
      return 15;
    },
    loadMap() {
      return new Promise((resolve) => {
        if (this.$refs.mapRef) {
          this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = this.map ?? map;
            resolve(map);
          });
        }
      });
    },
    buildMarkers(layerObj) {
      let layer = Object.assign({}, layerObj);
      let polygonBounds = new google.maps.LatLngBounds();

      if(this.parcel_uuid && this.parcel_uuid.length > 0){
        this.bounds = new google.maps.LatLngBounds();
      }
      else{
        this.bounds = this.bounds ?? new google.maps.LatLngBounds();
      }

      return new Promise((resolve) => {

        //build new marker
        layer.marker = {
          parcel_uuid: layer.parcel_uuid,
          label: {
            text: layer.name,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '12px'
          },
          icon: 'data:image/svg+xml',
          visibility: false,
        };

        layer.cultureMarker = {
          label: {
            text: layer.culture ? layer.culture.name : '',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '12px',
            className: 'culture-marker-position',
          },
          icon: 'data:image/svg+xml',
          visibility: false,
        };

        layer.scoutingMarker = {
          parcel_uuid: layer.parcel_uuid,
          label: {
            text: layer.last_active_scouting ? layer.last_active_scouting.reported_at.substring(0, layer.last_active_scouting.reported_at.indexOf(' ')) : '',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '12px',
            className: 'marker-position',
          },
          icon: 'data:image/svg+xml',
          icon_path: "circle-check-solid.svg",
          visibility: false,
        };
        layer.options = this.getLayerOptions(layer);
        layer.paths = [];
        layer.bounds = {};
        if(layer.last_active_scouting){
          if(layer.last_active_scouting.priority == 1){
            layer.scoutingMarker.icon_path = "alert_low.svg";
          }
          else if(layer.last_active_scouting.priority == 2){
            layer.scoutingMarker.icon_path = "alert_med.svg";
          }
          else if(layer.last_active_scouting.priority == 3){
            layer.scoutingMarker.icon_path = "alert_high.svg";
          }
        }

        this.setLayerCoordinatesForPolygon(layer, polygonBounds);

        resolve(layer);
      });
    },
    setLayerCoordinatesForPolygon(pLayer, polygonBounds){
      pLayer.paths = [];
      //iterate coordinates
      pLayer.data.coordinates.map((coordList)=>{
          let path = [];
          coordList.filter((coord) => { //romanian coordinates
            return coord[0] > 20;
          }).map((coord) => { //iterated coords

            if((this.parcel_uuid && this.parcel_uuid.length > 0 && this.parcel_uuid == pLayer.parcel_uuid) || !this.parcel_uuid){
              this.bounds.extend({
                lat: parseFloat(coord[1]),
                lng: parseFloat(coord[0]),
              });

              if (this.bounds && this.map) {
                this.map.fitBounds(this.bounds);
                this.map.panToBounds(this.bounds);
              }
            }

            //build paths
            path.push({
              lat: parseFloat(coord[1]),
              lng: parseFloat(coord[0]),
            });

            //get center position for marker
            polygonBounds.extend({
              lat: parseFloat(coord[1]),
              lng: parseFloat(coord[0]),
            });

            //set markers position
            pLayer.marker.position = {
              lat: polygonBounds.getCenter().lat(),
              lng: polygonBounds.getCenter().lng(),
            };

            pLayer.cultureMarker.position = {
              lat: polygonBounds.getCenter().lat(),
              lng: polygonBounds.getCenter().lng(),
            };

            pLayer.scoutingMarker.position = {
              lat: polygonBounds.getCenter().lat(),
              lng: polygonBounds.getCenter().lng(),
            };

          });
          pLayer.paths.push(path)
        });
        pLayer.bounds = this.getPolygonBounds(new google.maps.Polygon({ paths: pLayer.paths }));
        //set layer size (using outer coords (paths[0]))
        pLayer.size = google.maps.geometry.spherical.computeArea(
          pLayer.paths[0]
        ).toFixed(0);

    },
    parcelClick(event, layer) {
      if(this.editModeIsActive){

        this.resetLayerByUUID(this.layerUUIDForEdit);
        this.layerUUIDForEdit = layer.uuid;

        if(this.$refs['gmp_' + layer.uuid] && this.$refs['gmp_' + layer.uuid].length > 0){
          let polygon = this.$refs['gmp_' + layer.uuid][0].$polygonObject;

          this.addEventsToPath(polygon.getPath());
          this.getBaseCoordinatedFromPath(polygon.getPath());

        }
      }
      else if (this.selection_mode) {
        if (layer.parcel_uuid && layer.parcel_uuid.length > 0) {
          if (layer.selected) {
            layer.selected = false;
            layer.options = layer.has_works ? hasWorksOptions : hasParcelOptions;
            this.$emit('removeSelectedParcel', layer);
          } else {
            layer.options = selectedlayerOtions;
            layer.selected = true;
            this.$emit('addSelectedParcel', layer);
          }
        } else {
          this.$emit('showToastMessage', 'error', 'Nu exită parcelă asociată la hartă. Contactează administratorul de sistem!');
        }
      } else {
        this.$emit("onParcelClicked", layer);
      }
    },
    addEventsToPath(path){
      google.maps.event.clearListeners(path, 'insert_at');
      google.maps.event.addListener(path, 'insert_at', getSelfBaseCoordinatedFromPath);

      google.maps.event.clearListeners(path, 'remove_at');
      google.maps.event.addListener(path, 'remove_at', getSelfBaseCoordinatedFromPath);

      google.maps.event.clearListeners(path, 'set_at');
      google.maps.event.addListener(path, 'set_at', getSelfBaseCoordinatedFromPath);

      let self = this;

      function getSelfBaseCoordinatedFromPath(){
        self.getBaseCoordinatedFromPath(path);
      }

    },

    getBaseCoordinatedFromPath(path){
        let points = [];
        for (var i = 0; i < path.length; i++) {
            var point = [
              path.getAt(i).lat(),
              path.getAt(i).lng()
            ];
            points.push(point);
        }

        points.push(points[0]);

        let layerObject = {
          name: 'TS'+ Math.random(),
          surface: google.maps.geometry.spherical.computeArea(
            points.map((c) => {
              return {
                lat: c[0],
                lng: c[1],
              };
            })
          ).toFixed(2),
          coordinates: points
        };

        this.calculatedSurface = (layerObject.surface/10000).toFixed(2);
        this.newLayerAddress = layerObject;
    },

    updateLayer(){
      this.$store.dispatch('layers/getByUUID', {
          uuid: this.layerUUIDForEdit
      }).then((getResp) => {
        if(this.newLayerAddress.coordinates.length > 3 &&
        this.newLayerAddress.coordinates[this.newLayerAddress.coordinates.length] ==this.newLayerAddress.coordinates[this.newLayerAddress.coordinates.length - 1] ){
          this.newLayerAddress.coordinates.pop();
        }

        let layerPoints = {
          name : getResp.name,
          surface: this.newLayerAddress.surface,
          coordinates: this.newLayerAddress.coordinates
        };
        this.$store.dispatch('layers/updateLayerCoordinates', //getResp
          {
            uuid: getResp.uuid,
            data: { layers: [layerPoints] },
          }
        ).then((updResp) => {

            this.editModeIsActive = false;
            this.layerUUIDForEdit = null;

            if(updResp.uuid && updResp.length > 0){
              if(this.$refs['gmp_' + updResp.uuid] && this.$refs['gmp_' + updResp.uuid].length > 0){
                let oldPolygon = this.$refs['gmp_' + updResp.uuid][0].$polygonObject;

                let oldPoligonLayer = this.parcelLayers.find(l => l.uuid == updResp.uuid);
                if(oldPoligonLayer){
                  this.bounds = this.bounds ?? new google.maps.LatLngBounds();
                  this.setLayerCoordinatesForPolygon(oldPoligonLayer, polygonBounds)
                }

                if(oldPolygon && oldPoligonLayer ){
                  oldPolygon.setPaths(oldPoligonLayer.paths);
                }

              }
            }

            this.$emit('showToastMessage', 'success', 'Parcela a fost salvată cu succes!');

        }).catch((e) => {
          this.$emit('showToastMessage', 'error', 'A apărut o erroare la salvarea parcelei!');
        });
      }).catch((e) => {

      });

    },

    resetSelection() {
      this.parcelLayers.forEach(layer => {
        layer.options = layer.has_works ? hasWorksOptions : hasParcelOptions;
      });
    },
    async loadLayers(season_uuid, force_load = false) {
      force_load = true;
      this.$set(this, 'parcelLayers', []);
      let layer_season_changed = false;
      if(
        (season_uuid && season_uuid.length > 0 && this.$store.state.layers.layer_season_uuid != season_uuid) ||
        (this.layers.length > 0 && this.layers[0].season_uuid != this.$store.state.layers.layer_season_uuid) ||
        force_load){
        this.$store.commit("layers/"+type.SET_LAYER_SEASON, season_uuid);
        layer_season_changed = true
      }
      if(layer_season_changed || this.parcel_uuid){
        if(force_load && this.$refs.mapFilterDialog){
          this.$refs.mapFilterDialog.setSeason(this.filterData.season_uuid, this.filterData.season_name);
          this.$refs.mapFilterDialog.applyMapFilterAtMapReady();
        }
        else{
          await this.getLayers();
        }
      }
      else{
        this.handleStoredLayerListChanges(this.layers);
      }
    },
    async getLayers() {
      let vm = this;
      vm.loading = true;

      this.$store.commit("layers/"+type.SET_LAYERS_LOADING, true);

      let localFIlter = {
        department_uuid: '',
        season_uuid: '',
        culture_uuid: '',
        variety_uuid:''
      };

      if(this.parcel_uuid && this.parcel_uuid.length > 0){
        localFIlter.department_uuid = this.departments.map(d => d.uuid).join();
      }
      else if(this.filterData && this.filterData.department_uuids && this.filterData.department_uuids.length > 0){
        localFIlter.department_uuid = getStringOrJoinArray(this.filterData.department_uuids);
      }
      else if(this.layer_filters && this.layer_filters.department_uuids && this.layer_filters.department_uuids.length > 0){
        localFIlter.department_uuid = getStringOrJoinArray(this.layer_filters.department_uuids);
      }
      else{
        localFIlter.department_uuid =  this.$store.getters['auth/currentDepartment'].department_uuid;
      }

      if(this.filterData && this.filterData.season_uuid && this.filterData.season_uuid.length > 0){
        localFIlter.season_uuid = this.filterData.season_uuid;
      }
      else if(this.layer_filters && this.layer_filters.season_uuid && this.layer_filters.season_uuid.length > 0){
        localFIlter.season_uuid = this.layer_filters.season_uuid;
      }
      else{
        localFIlter.season_uuid =  vm.layer_season_uuid;
      }

      if(this.filterData && this.filterData.culture_uuids && this.filterData.culture_uuids.length > 0 && !this.parcel_uuid){
        localFIlter.culture_uuid = getStringOrJoinArray(this.filterData.culture_uuids);
      }
      else{
        localFIlter.culture_uuid =  '';
      }

      if(this.filterData && this.filterData.sub_culture_uuids && this.filterData.sub_culture_uuids.length > 0 && !this.parcel_uuid){
        localFIlter.variety_uuid = this.filterData.sub_culture_uuids.join();
      }
      else{
        localFIlter.variety_uuid =  '';
      }
      if(!this.parcel_uuid){
        localFIlter.name = this.filterData.name;
      }
      return await this.$store.dispatch('layers/getLayers', localFIlter
      //{
        //department_uuid: this.$store.getters['auth/currentDepartment'].department_uuid,
        //season_uuid: vm.layer_season_uuid,
      //}
      ).then((resp) => {
        vm.loading = false;
      }).catch((e) => {
        vm.loading = false;
        vm.error = 'Error occurred while loading layers! ' + e.message;
      });


    },
    async refreshLayers() {
      this.colorFilterIsActive = false;
      //this.$emit('showToastMessage', 'info', 'Parcelele sunt în curs de actualizare ...');
      await this.getLayers();
      this.handleStoredLayerListChanges(this.layers);
    },
    sortLayers(sourceArray, newObject) {
      sourceArray.push(newObject);
      return sourceArray.sort((a, b) => Number(a.size) > Number(b.size) ? 1 : -1);
    },
    getLayerOptions(layer) {
      if(this.showScoutinMarkers(layer, 'getLayerOptions')){
        return scoutinglayerOtions;
      }
      else if (layer.has_works) {
        return hasWorksOptions;
      }

      else if (layer.has_parcel) {
        return hasParcelOptions;
      }
    },
    handleMarkersVisibility(){
      this.parcelLayers.forEach((layer) => {
          layer.marker.visibility = layer.bounds.intersects(this.map.getBounds()) && this.map.getZoom() > this.hide_on_zoom_level;
      });
    },
    handleZoom(inc) {
      let parcelLayersLength = this.parcelLayers.length;
      if (parcelLayersLength === 0) return;

      let chunkSize = ((this.max_zoom_level - this.hide_on_zoom_level) + this.chunkSizeInc);
      let layerChunks = Math.round(parcelLayersLength / chunkSize);
      let currentZoomLevel = this.map.getZoom();

      console.log(currentZoomLevel);

      //hide all
      if (currentZoomLevel <= this.hide_on_zoom_level) {
        this.parcelLayers.map((layer) => {
          this.$set(layer.marker, 'visibility', false);
          this.$set(layer.scoutingMarker, 'visibility', false);
          this.$set(layer.cultureMarker, 'visibility', false);
        });
        this.zoomIndex = -1;
      }
      // show all above the max_zoom_level with increasing zoom level
      else if (currentZoomLevel > this.max_zoom_level && inc) {
        if(!this.showAllMarkers) {
          this.parcelLayers.map((layer) => {
            //this.$set(layer.marker, 'visibility', true);
            this.$set(layer.scoutingMarker, 'visibility', true);
            this.$set(layer.cultureMarker, 'visibility', true);
          });
          this.showAllMarkers = true;
        }
        this.zoomIndex++;
      } else {
        //show on level
        if (inc) {
          this.zoomIndex++;
        }

        let fromIndex = 0;
        let toIndex = 0;
        if (this.showAllMarkers && this.zoomIndex < chunkSize) {
          fromIndex = layerChunks * Math.abs(this.zoomIndex);
          toIndex = parcelLayersLength;
          this.showAllMarkers = false;
        } else {
          fromIndex = layerChunks * Math.abs(this.zoomIndex);
          toIndex = fromIndex + layerChunks;
        }

        this.showHideMarkers(fromIndex, toIndex, inc);

        if (!inc) {
          this.zoomIndex--;
        }
      }
    },
    showHideMarkers(fromIndex, toIndex, inc) {
      for (let i = fromIndex; i <= toIndex; i++) {
        if (this.parcelLayers[this.parcelLayers.length - i]) {
          let layer = this.parcelLayers[this.parcelLayers.length - i];
          //this.$set(layer.marker, 'visibility', inc);
          this.$set(layer.scoutingMarker, 'visibility', inc);
          this.$set(layer.cultureMarker, 'visibility', inc);
        }
      }
    },
   loadMapDrawingManager() {
				let self = this;
        if(this.drawingIsActivated == false){
          self.calculatedSurface = 0;
          this.$refs.layerDetailDialog.resetForm();
          this.drawingIsActivated = true;
          this.$refs.mapRef.$mapPromise.then((mapObject) => {
            this.drawingManager = new google.maps.drawing.DrawingManager({
              ref: "mapDrawing",
              drawingMode: google.maps.drawing.OverlayType.POLYGON,
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingControl: false,
              drawingModes: [
                google.maps.drawing.OverlayType.POLYGON,

              ],
              markerOptions: {
                icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
              },
              polygonOptions: {
                  fillColor: '#3AFF00',
                  fillOpacity: 0.5,
                  strokeColor: '#3AFF00',
                  strokeWeight: 4,
                  editable: true
              },
              polylineOptions: {
                  fillColor: '#3AFF00',
                  fillOpacity: 1,
                  strokeColor: '#3AFF00',
                  strokeWeight: 4,
                  editable: true
              }
            });
            this.drawingManager.setMap(this.$refs.mapRef.$mapObject);


            google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function (event) {
              //setTimeout(self.addClickEventToDrawingPoints, 300);
              event.overlay.addListener("click", self.getPolygonArrays);
              self.drawedLayers.push(event);
              //self.theNewPoligonLayerSet(event);

              google.maps.event.addListener(event.overlay.getPath(), 'insert_at', getCoordinatesAtChanges);
              // move vertex listener
              google.maps.event.addListener(event.overlay.getPath(), 'set_at', getCoordinatesAtChanges);

              //create new layer & name with coords
              getCoordinatedFromPath(event.overlay.getPath());

              // // Remove overlay from map
              // event.overlay.setMap(null);

              // // Disable drawingManager
              self.drawingManager.setDrawingMode('');
              self.showNewlayerSave = true;

              // // Create Polygon
              // self.savePolygon(paths);
            });

            function getCoordinatesAtChanges(index, element) {
              getCoordinatedFromPath(self.drawedLayers[0].overlay.getPath());
            }

            function getCoordinatedFromPath(path){
              let points = [];
              for (var i = 0; i < path.length; i++) {
                  var point = [
                    path.getAt(i).lat(),
                    path.getAt(i).lng()
                  ];
                  points.push(point);
              }

              points.push(points[0]);

              let layerObject = {
                name: 'TS'+ Math.random(),
                surface: google.maps.geometry.spherical.computeArea(
                  points.map((c) => {
                    return {
                      lat: c[0],
                      lng: c[1],
                    };
                  })
                ).toFixed(2),
                coordinates: points
              };

              self.calculatedSurface = (layerObject.surface/10000).toFixed(2);
              self.newLayerAddress = layerObject;
            }

          });

        }
        else{
          this.showNewlayerSave = false;
          this.drawingIsActivated = false;
          this.drawedLayers.forEach(l => {
             l.overlay.setMap(null);
          });
          this.drawedLayers = [];
          this.drawingManager.setMap(null);
        }
			},
      theNewPoligonLayerSet(val){
        this.$set(this, 'theNewPoligonLayer', val);
      },
      addClickEventToDrawingPoints(){
        let vm = this;
        const drawPoints = $(".gmap-map div[style='position: absolute; left: 0px; top: 0px; z-index: 0;'] div[style*='display: block;'] ");
        vm.drawingPoints = drawPoints;
        if(drawPoints && drawPoints.length > 0){
         $.each(drawPoints, function(i, val){

          var eventObject = $._data($(val).get(0), 'events');

          if(eventObject == undefined || eventObject.click == undefined){
            $(val).click(function(){
               $.each(vm.drawingPoints, function(j, point){
                  $(point).css("background-color", "transparent");
               });

              let children = $(val).children("div[style*='position: absolute;']");
              if(children && children.length > 0){

              }
              $(val).children("div[style*='position: absolute;']")[0].css("background-color", "blue !important");

              $(val).css("background-color", "white !important");
              $(val).css("position", "relative !important");
              $(val).css("background", "url('~@/assets/images/icons/drawing_pointer.svg') !important");
              $(val).css("background-size", "43px 39px");
              $(val).css("background-position", "-20px -20px");
              $(val).css("background-repeat", "no-repeat");
              $(val).css("width", "40px !important");
              $(val).css("height", "45px !important");
              $(val).css("top", "-30px !important");
              $(val).css("left", "-25px !important");
              $(val).css("border", "none !important");
            });
          }
         });
        }
      },

     stopWatchingCurrentPosition(){
      navigator.geolocation.clearWatch(this.currentLocationWatcher);
          this.currentLocationWatcher = null;
          if(this.currentLocationMarker != null){
            this.currentLocationMarker.setMap(null);
            this.currentLocationMarker = null;
          }
          if(this.currentLocationTimeout != null){
            clearTimeout(this.currentLocationTimeout);
            this.currentLocationTimeout = null;
          }
     },

      goToCurrentLocation(){
        if(this.currentLocationWatcher == null || this.currentLocationWatcher == undefined){

          this.currentLocationWatcher = navigator.geolocation.watchPosition((position) => {
            this.$refs.mapRef.$mapPromise.then((map) => {
                  var myLatlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                  if(this.currentLocationMarker != null){
                    this.currentLocationMarker.setMap(null);
                    this.currentLocationMarker = null;
                  }
                  this.currentLocationMarker = new google.maps.Marker({
                      position: myLatlng,
                      title:"Locația curentă",
                      map: this.map
                  });
                  map.panTo({lat: position.coords.latitude, lng: position.coords.longitude})
                  if(this.currentLocationTimeout == null){
                    this.currentLocationTimeout = setTimeout(this.stopWatchingCurrentPosition, 1800000);
                  }
                  else{
                    clearTimeout(this.currentLocationTimeout);
                    this.currentLocationTimeout = null;
                    this.currentLocationTimeout = setTimeout(this.stopWatchingCurrentPosition, 1800000);
                  }
                })
          }, (err) => {},
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        }
        else{
          this.stopWatchingCurrentPosition();
        }
        //  navigator.geolocation.getCurrentPosition((position) => {
        //    this.$emit('showToastMessage', 'info', 'Locația: ' + position.coords.latitude + ', ' + position.coords.longitude + '.');
        //       this.$refs.mapRef.$mapPromise.then((map) => {
        //         var myLatlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        //         if(this.currentLocationMarker != null){
        //           this.currentLocationMarker.setMap(null);
        //           this.currentLocationMarker = null;
        //         }
        //         this.currentLocationMarker = new google.maps.Marker({
        //             position: myLatlng,
        //             title:"Locația curentă",
        //             map: this.map
        //         });
        //         map.panTo({lat: position.coords.latitude, lng: position.coords.longitude})

        //       })
        //   },
        //   (err) => {
        //     if(err.code == 1){
        //       this.$emit('showToastMessage', 'error', 'Accesul la geolocație este blocată! Trebuie să setezi permisiunile pentru geolocație în setările dispozitivului.');
        //     }
        //     else{
        //       this.$emit('showToastMessage', 'error', err.message);
        //     }
        //   },
        //   {
        //     enableHighAccuracy: true,
        //     timeout: 5000,
        //     maximumAge: 0,
        //   }
        // );
    },

    getPolygonArrays(event){
        //this.addClickEventToDrawingPoints();
    },
    async saveDrawedLayer(){
      let vm = this;
      await this.$store.dispatch('layers/createLayer', {
          department_uuid: this.$store.getters['auth/currentDepartment'].department_uuid,
          season_uuid: this.layer_season_uuid,
          data: { layers: [this.newLayerAddress] }
        }).then((resp) => {
          vm.newLayerAddress = null;
          vm.showNewlayerSave = false;
          vm.$emit('showToastMessage', 'success', 'Parcelele au fost salvate');
        }).catch((e) => {
          console.error('Layer SAVE error: ', e);
          vm.$emit('showToastMessage', 'error', e.response.data.message);
        });
    },
     handleStoredLayerListChanges(newVal){
       this.parcelLayers = [];
        if (this.mapReady) {
          newVal.map((layer) => {
            this.buildMarkers(layer).then((newLayer) => {
              this.sortLayers(this.parcelLayers, newLayer)
            });
          });
          //reset zoomIndex on changing an agricol
          this.zoomIndex = -1;
          //this.handleZoom(false);
        }
    },
    setCurrentSeason(){
      let forceLoad = false;
      if(this.layer_season_uuid === undefined || this.layer_season_uuid == ''){
        forceLoad = true;
        let tmp_season = this.seasons[0];
        this.seasons.forEach((obj) => {
          if(new Date(obj.year) >= new Date(tmp_season.year)){
            this.filterData.season_name = obj.name;
            this.filterData.season_uuid = obj.uuid;
            this.$store.commit("layers/"+type.SET_LAYER_SEASON, obj.uuid);
          }
        });
      }
      else{
        this.seasons.forEach((obj) => {
          if(obj.uuid == this.layer_season_uuid){
            this.filterData.season_name = obj.name;
            this.filterData.season_uuid = obj.uuid;
          }
        });
      }
      return forceLoad;
    },
    getPolygonBounds(polygon){

        var path = polygon.getPath();

        var slat, blat = path.getAt(0).lat();
        var slng, blng = path.getAt(0).lng();

        for (var i = 1; i < path.getLength(); i++) {
          var e = path.getAt(i);
          slat = ((slat < e.lat())?slat:e.lat());
          blat = ((blat > e.lat())?blat:e.lat());
          slng = ((slng < e.lng())?slng:e.lng());
          blng = ((blng > e.lng())?blng:e.lng());
        }

        return new google.maps.LatLngBounds(new google.maps.LatLng(slat, slng), new google.maps.LatLng(blat, blng));
    }
  },
  mounted() {
    if(this.initMapInMount){
      console.log("Load map at mount");
       let forceLoad = this.setCurrentSeason();
          if(this.$refs.mapFilterDialog){
            this.$refs.mapFilterDialog.setSeason(this.filterData.season_uuid, this.filterData.season_name);
          }
          if (!this.mapReady) {
            this.loadMap().then((map) => {
              this.loadLayers(this.layer_season_uuid, forceLoad);
            });
          }
    }
  },
  watch: {
    zoomLevel(newVal, oldVal) {
      this.handleMarkersVisibility();
      //this.handleZoom(newVal > oldVal);
    },
    showMap: {
      handler: function (newVal) {
        if(newVal){
          if(!this.initMapInMount){
            console.log("Load map at watch");
            let forceLoad = this.setCurrentSeason();
            if(this.$refs.mapFilterDialog){
              this.$refs.mapFilterDialog.setSeason(this.filterData.season_uuid, this.filterData.season_name);
            }
            if (!this.mapReady) {
              this.loadMap().then((map) => {
                this.loadLayers(this.layer_season_uuid, forceLoad);
              });
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    theNewPoligonLayer: {
      handler: function (newVal) {
        //console.log('the new poligon was changed');
      },
      deep: true,
      immediate: true,
    },
    layers_loading: {
      handler: function (newVal, oldVal){
        if(newVal == false){
          this.handleStoredLayerListChanges(this.layers);
        }
      },
      immediate: true
    },
    loading(newVal) {
      if (!newVal) {
        this.error = '';
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/map.scss";

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
  background-color: #333;
  opacity: 0.5;
}

.refresh-icon-holder {
  position: relative;
  right: 0;
  top: 147px;

  .r-wrapper {
    background-color: white;
    width: 34px;
    height: 34px;

    .refresh-btn {
      font-size: 17px;
      color: #238F3D;
      padding-top: 8px;
      padding-right: 9px;
    }
  }
}
</style>
